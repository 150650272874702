<template>
  <div>
    <div class="newEmail">
      <div class="newEmailTop">
        <p class="eTitle">{{ headerTempTitle }}</p>
        <p class="eContent">{{ $t("label.emailtemplate.usemergefields") }}</p>
      </div>
      <div class="newEmailtip">
        <p class="tipTitle">
          {{ $t("label.emailtemplate.mergefields.tip") }}
        </p>
        <p class="tipContent">
          {{ $t("label.emailtemplate.availablemergefields") }}
        </p>
      </div>
      <div class="selectBorder">
        <div class="selectpart">
          <div class="selectObj">
            <span>{{ $t("label.selectobject") }}</span>
            <el-select
              v-model="Objvalue"
              :placeholder="$t('label.tabpage.pleaseSelectz')"
              @change="ObjChange"
            >
              <el-option
                v-for="(item, index) in Objoptions"
                :key="index"
                :label="item.labelname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="selectItem">
            <span>{{ $t("label.selectfield") }}</span>
            <el-select
              v-model="Fieldvalue"
              :placeholder="$t('label.tabpage.pleaseSelectz')"
            >
              <el-option
                v-for="(item, index) in Fieldoptions"
                :key="index"
                :label="item.labelname"
                :value="item.fieldname"
                @click.native="FieldChange(index)"
              >
              </el-option>
            </el-select>
          </div>
          <div class="selectItem" v-show="showselectField">
            <span>{{ $t("label.selectfield") }}</span>
            <el-select
              v-model="FieldvalueThree"
              :placeholder="$t('label.tabpage.pleaseSelectz')"
              @change="FieldChangeThree"
            >
              <el-option
                v-for="(item, index) in FieldoptionsThree"
                :key="index"
                :label="item.labelname"
                :value="item.fieldname"
              >
              </el-option>
            </el-select>
          </div>
          <div class="mergeItem">
            <span>{{ $t("label.emailtemplate.mergefield") }}</span>
            <el-input
              v-model="inputMerge"
              :placeholder="$t('label.tabpage.entercontent')"
              @focus="copyText(inputMerge)"
              class="btn"
            ></el-input>
            <span>{{ $t("label.emailtemplate.mergefield.use") }}</span>
          </div>
        </div>
        <p>{{ $t("label.emailtemplate.finishdetailinfo") }}</p>
      </div>
      <!-- 模板表单 -->
      <div class="temFormClass">
        <div class="temPosi">
          <el-form
            ref="temForm"
            :model="temForm"
            label-width="100px"
            :rules="temrules"
            :inline-message="true"
          >
            <el-form-item :label="$t('label.istouse')">
              <el-checkbox v-model="temForm.checked"></el-checkbox>
            </el-form-item>
            <el-form-item prop="temName">
              <label slot="label"
                ><span style="color: red">*</span>&nbsp;{{
                  $t("label.emailobject.template.name")
                }}</label
              >
              <el-input
                :disabled="evToeditTem === 'evluation'"
                v-model="temForm.temName"
                maxlength="255"
                :placeholder="$t('label.tabpage.entercontent')"
              ></el-input>
            </el-form-item>
            <el-form-item prop="selectFolder">
              <label slot="label"
                ><span style="color: red">*</span>&nbsp;{{
                  $t("vue_label_report_selectfolder")
                }}</label
              >
              <el-select
                :disabled="evToeditTem === 'evluation'"
                v-model="temForm.selectFolder"
                :placeholder="$t('label.tabpage.pleaseSelectz')"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="(item, index) in folderoptions"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="uniqueName">
              <label slot="label"
                ><span style="color: red">*</span>&nbsp;{{
                  $t("label.uniquename")
                }}</label
              >
              <el-input
                :disabled="evToeditTem === 'evluation'"
                v-model="temForm.uniqueName"
                :placeholder="$t('label.tabpage.entercontent')"
                @input="accountInput"
              ></el-input>
            </el-form-item>
            <el-form-item prop="comment" :label="$t('label.description')">
              <el-input
                v-model="temForm.comment"
                :placeholder="$t('label.tabpage.entercontent')"
                maxlength="255"
              ></el-input>
            </el-form-item>
            <!-- 主题 -->
            <el-form-item prop="theme">
              <label slot="label"
                ><span style="color: red">*</span>&nbsp;{{
                  $t("label.subject")
                }}</label
              >
              <el-input
                v-model="temForm.theme"
                :placeholder="$t('label.tabpage.entercontent')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('label.emailmainbody')">
              <!-- 富文本 -->
              <div v-if="judgeTypeProp === '2'">
                <wangeditor ref="wangeditor" @editorContent="editorContent">
                </wangeditor>
              </div>
              <!-- 长文本 -->
              <div v-if="judgeTypeProp === '1'">
                <el-input
                  type="textarea"
                  :rows="10"
                  :placeholder="$t('label.tabpage.entercontent')"
                  v-model="temForm.textarea"
                  maxlength="32000"
                >
                </el-input>
              </div>
            </el-form-item>
            <div class="btnPosi">
              <el-button
                plain
                v-show="headerTemp === 'new'"
                @click="lastStep"
                >{{ $t("label.import.page1.laststep") }}</el-button
              >
              <el-button plain @click="TempleteSave('temForm')">{{
                $t("label.chatter.save")
              }}</el-button>
              <el-button
                plain
                @click="cancelNewTem('temForm')"
                v-show="evToeditTem === ''"
                >{{ $t("label.tabpage.cancel") }}</el-button
              >
              <!-- 从客户满意度 评价设置的页面过来 显示的取消按钮  wwwjjj-->
              <el-button
                plain
                @click="backToEv"
                v-show="evToeditTem === 'evluation'"
                >{{ $t("label.tabpage.cancel") }}</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import wangeditor from "@/components/emailEditor";
import * as emailTemplate from "../../../../../systemSettingsApi";
import * as Emailobject from "../../../../../systemSettingsApi.js";
import { copyText } from "@/utils/copyInfo";
export default {
  components: { wangeditor },
  props: {
    // 判断是文本还是HTML
    judgeType: {
      type: String,
      default: "",
    },
    headerTemp: {
      type: String,
      default: "",
    },
    editTemInfo: {
      type: Object,
      default: ()=>{},
    },
    evToeditTem: {
      type: String, //从客户满意度 评价设置的页面传过来的 wwwjjj
      default: "",
    },
  },

  data() {
    var temNameValidate = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$i18n.t("label.value.notnull")));
      } else {
        callback();
      }
    };
    var selectFolderValidate = (rule, value, callback) => {
      if (!value || value === "") {
        callback(new Error(this.$i18n.t("label.value.notnull")));
      } else {
        callback();
      }
    };
    var uniqueNameValidate = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$i18n.t("label.value.notnull")));
      } else {
        callback();
      }
    };
    var themeValidate = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$i18n.t("label.value.notnull")));
      } else {
        callback();
      }
    };
    return {
    editTemInfoProp: this.editTemInfo,
    judgeTypeProp: this.judgeType,
      // 默认标题
      headerTempTitle: "",
      // 模板表单
      temForm: {
        checked: false,
        temName: "",
        selectFolder: "baf20211018template1",
        uniqueName: "",
        comment: "",
        theme: "",
        textarea: "",
      },
      // 富文本内容
      editorText: "",
      folderoptions: [],
      temrules: {
        temName: [{ validator: temNameValidate, trigger: "blur" }],
        selectFolder: [{ validator: selectFolderValidate }],
        uniqueName: [{ validator: uniqueNameValidate, trigger: "blur" }],
        theme: [{ validator: themeValidate, trigger: "blur" }],
      },
      // 选择对象
      Objvalue: "",
      Objoptions: [],
      // 选择字段
      Fieldvalue: "",
      Fieldoptions: [],
      FieldvalueThree: "",
      FieldoptionsThree: [],
      obgMerge: "",
      inputMerge: "",
      // 默认第三级选择字段不存在
      showselectField: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // 赋值合并字段值
    copyText,
    // 初始化
    init() {
      let params = {
        foldertype: "email",
        backgroundSetting: true,
        purview:""
      };
      emailTemplate.queryTemplateFolder(params).then((res) => {
        this.folderoptions = res.data;
      });
      if (this.headerTemp === "new") {
        this.headerTempTitle = this.$i18n.t("label.emailtemplate.new");
      } else if (this.headerTemp === "edit") {
        this.headerTempTitle = this.$i18n.t("label.emailtemplate.edit");
        let params = {
          id:
            this.evToeditTem === "evluation"
              ? "bccServiceEvaluatioz"
              : this.editTemInfoProp.id,
        };
        emailTemplate.queryTemplateById(params).then((res) => {
          let isEnableBool = res.data[0].isEnable === "true" ? true : false;
          this.temForm.checked = isEnableBool;
          this.temForm.temName = res.data[0].name;
          this.temForm.selectFolder = res.data[0].folderid;
          this.temForm.uniqueName = res.data[0].apiCode;
          this.temForm.comment = res.data[0].remark;
          this.temForm.theme = res.data[0].title;
          if (res.data[0].isText === "true") {
            this.temForm.textarea = res.data[0].mainbody;
          } else if (res.data[0].isText === "false") {
            this.$nextTick(() => {
              this.$refs.wangeditor.clearvalue();
              this.$refs.wangeditor.signContent(
                res.data[0].mainbody == undefined ? "" : res.data[0].mainbody
              );
            });
          }
        });
        if (this.editTemInfoProp.isText === "true") {
          this.judgeTypeProp = "1";
        } else if (this.editTemInfoProp.isText === "false") {
          this.judgeTypeProp = "2";
        } else {
          this.judgeTypeProp = "1";
        }
      }
      this.getObjectList();
    },

    //获取对象列表
    async getObjectList() {
      let result = await Emailobject.getobjectlistB();
      if (result.result == true && result.data) {
        this.Objoptions = result.data;
      }
    },

    // 选择对象
    async ObjChange() {
      this.Fieldvalue = "";
      this.FieldvalueThree = "";
      this.inputMerge = "";
      let params = {
        objid: this.Objvalue,
        num: 1,
      };
      let result = await Emailobject.getsize(params);
      this.Fieldoptions = result.data.fields;
      this.obgMerge = result.data.objectmap.objectname;
    },

    // 选择字段
    async FieldChange(index) {
      this.FieldvalueThree = "";
      this.inputMerge = "";
      // 判断第二级是否是查找字段
      let params = {
        objid: this.Objvalue,
        num: 1,
      };
      let result = await Emailobject.getsize(params);
      // 如果存在
      if (result.data.fields[index].lookupObj) {
        this.showselectField = true;
        let params = {
          objid: result.data.fields[index].lookupObj,
          num: 2,
        };
        let resultThree = await Emailobject.getsize(params);
        this.FieldoptionsThree = resultThree.data.fields;
      } else {
        this.showselectField = false;
        this.inputMerge = "{!" + this.obgMerge + "." + this.Fieldvalue + "}";
      }
    },
    FieldChangeThree() {
      this.inputMerge =
        "{!" +
        this.obgMerge +
        "." +
        this.Fieldvalue +
        "." +
        this.FieldvalueThree +
        "}";
    },

    // 上一步
    lastStep() {
      this.$emit("lastStepClick");
    },
    // 模板保存
    TempleteSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {};
          if (this.headerTemp === "edit") {
            params = {
              etype: "EMAIL",
              remark: this.temForm.comment,
              encoding: "GBK",
              title: this.temForm.theme,
              isText: this.judgeTypeProp === "1" ? "true" : "false",
              mainbody:
                this.editTemInfoProp.isText === "true"
                  ? this.temForm.textarea
                  : this.editorText,
              templateFolderId: this.temForm.selectFolder,
              isEnable: this.temForm.checked ? "true" : "false",
              name: this.temForm.temName,
              apiCode: this.temForm.uniqueName,
              id:
                this.evToeditTem === "evluation"
                  ? "bccServiceEvaluatioz"
                  : this.editTemInfoProp.id,
            };
          } else if (this.headerTemp === "new") {
            params = {
              etype: "EMAIL",
              remark: this.temForm.comment,
              encoding: "GBK",
              title: this.temForm.theme,
              isText: this.judgeTypeProp === "1" ? "true" : "false",
              mainbody:
                this.judgeTypeProp == "1" ? this.temForm.textarea : this.editorText,
              templateFolderId: this.temForm.selectFolder,
              isEnable: this.temForm.checked ? "true" : "false",
              name: this.temForm.temName,
              apiCode: this.temForm.uniqueName,
            };
          }
          emailTemplate.saveTemplate(params).then((res) => {
            if (res.result) {
              this.$message({
                message: this.$i18n.t("label.search.saveok"),
                type: "success",
              });
              this.cancelNewTem("temForm");
            }
          });
          // 返回 客户满意度 评价设置的页面   wwwjjj
          if (this.evToeditTem === "evluation") {
            this.$emit("goBackToEv");
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    },
    // 取消
    cancelNewTem(formName) {
      this.$refs[formName].resetFields();
      this.$emit("cancelNewTemplete");
    },
    // 返回服务评价 wwwjjj
    backToEv() {
      this.$emit("goBackToEv");
    },
    // 富文本赋值
    editorContent(val) {
      this.editorText = val;
    },

    // 唯一名称只限字母和数字
    accountInput(val) {
      let codeReg = new RegExp("[A-Za-z0-9]+"), //正则 英文+数字；
        len = val.length,
        str = "";
      for (var i = 0; i < len; i++) {
        if (codeReg.test(val[i])) {
          str += val[i];
        }
      }
      this.temForm.uniqueName = str;
    },
  },
};
</script>
<style lang="scss" scoped>
.newEmail {
  .newEmailTop {
    padding: 16px 10px 0 10px;
    min-height: 86px;
    border-bottom: 1px #dedcda solid;
    .eTitle {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    .eContent {
      font-size: 16px;
      color: #333333;
      margin-top: 16px;
    }
  }
  .newEmailtip {
    min-height: 75px;
    border-bottom: 1px #dedcda solid;
    padding: 15px 0 10px 10px;
    .tipTitle {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
    .tipContent {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      padding-top: 10px;
    }
  }
  .selectBorder {
    min-height: 91px;
    padding: 15px 0 10px 10px;
    border-bottom: 1px #dedcda solid;
    p {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    .selectpart {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 16px;
      .selectObj {
        ::v-deep .el-select {
          width: 70%;
          margin-left: 10px;
        }
        ::v-deep .el-select .el-input__inner {
          height: 30px;
        }
        ::v-deep .el-input__icon {
          line-height: 30px;
        }
      }
      .selectItem {
        margin-left: 10px;
        ::v-deep .el-select {
          width: 70%;
          margin-left: 10px;
        }
        ::v-deep .el-select .el-input__inner {
          height: 30px;
        }
        ::v-deep .el-input__icon {
          line-height: 30px;
        }
      }
      .mergeItem {
        margin-left: 10px;
        ::v-deep .el-input {
          width: 35%;
          margin-left: 10px;
          margin-right: 10px;
        }
        ::v-deep .el-input__inner {
          height: 30px;
        }
      }
    }
  }
  .temFormClass {
    display: flex;
    justify-content: center;
    .temPosi {
      width: 802px;
      ::v-deep .el-form-item {
        margin-bottom: 10px;
      }
      ::v-deep .el-input__inner {
        height: 30px;
      }
      ::v-deep .el-input {
        width: 628px;
      }
      .btnPosi {
        margin-top: 6px;
        margin-bottom: 16px;
        text-align: center;
      }
      ::v-deep .el-popper {
        z-index: 11000 !important;
      }
    }
  }
}
</style>