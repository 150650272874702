import axios from '@/config/httpConfig'
// 获取菜单列表
export function getSetupList(v) {
  return axios.post('setup/getSetupList', v)
}


// 查询发票在线支付配置
export function getEmailOnlinePaySetUp(v) {
  return axios.get('/emailonlinepaysetup/getEmailOnlinePaySetUp', v)
}
// 更新支付方式配置
export function updateEmailOnlinePaySetUp(v) {
  return axios.post('/emailonlinepaysetup/updateEmailOnlinePaySetUp', v)
}

// 系统设置(迁移的systemSetup)

// 获取进度条设置对象列表
export function getObjectList() {
  return axios.post("/progress/getObjectList");
}

// 获取进度条对象下选项列表类型字段列表
export function getSelectFieldList(data) {
  return axios.post("/progress/getSelectFieldList", data);
}

// 获取进度条阶段布局
export function getProgressLayout(data) {
  return axios.post("/progress/getProgressLayout", data);
}

// 获取进度条阶段列表值
export function getProgressSelectValues(data) {
  return axios.post("/progress/getProgressSelectValues", data);
}

// 获取进度条列表
export function getProgressSetupList() {
  return axios.post("/progress/getProgressSetupList");
}
// 保存进度条设置信息
export function saveProgressSetup(data) {
  return axios.post("/progress/saveProgressSetup", data);
}

// 删除进度条
export function deleteProgress(data) {
  return axios.post("/progress/deleteProgress", data);
}

// 获取详情页报表图表全部列表信息
export function getReportChartList(data) {
  return axios.post("/reportChartBase/queryReportChartList", data);
}

// 删除报表图表
export function removeReportChart(data) {
  return axios.post("/reportChartBase/removeReportChartRecord", data);
}
// 查询报表图表详情
export function getReportChartDetail(data) {
  return axios.post("/reportChartBase/queryReportChartDetail", data);
}

// 获取选择对象选择框数据的列表
export function getObjectListA(data) {
  return axios.post("/report/chart/queryObject", data);
}

// 获取记录类型选择框数据列表
export function getRecordTypeList(data) {
  return axios.post("/report/chart/findObjIdRecordType", data);
}

// 获取报表选择框数据的列表
export function getreportInformationList(data) {
  return axios.post("/report/chart/reportInformationQueryAll", data);
}
// 根据报表id查询报表所包含的对象
export function getObjectByReportid(data) {
  return axios.post("/report/chart/queryObjectByReportid", data);
}
// 详情页报表图表保存接口
export function saveReportChart(data) {
  return axios.post("/report/chart/save", data);
}
// 获取所仪表板列表
export function getDashboardList(data) {
  return axios.post('/dashboard/getDashboardList', data)
}


//查询docusign模板列表
export function getTemplatesList(data) {
  return axios.post('/docusign/getTemplatesList', data)
}
// 获取授权码
export function getAuthorizationCode() {
  return axios.get('/docusign/getAuthorizationCode')
}
// 获取访问令牌
export function getAccessToken(data) {
  return axios.post('/docusign/getAccessToken', data)
}
// 发起签署
export function envelopesCreate(data) {
  return axios.post('/docusign/envelopesCreate', data)
}
// 查询信封状态
export function envelopesStatus(data) {
  return axios.post('/docusign/envelopesStatus', data)
}
// 合同关联信封
export function updateEnvelopeidToContract(data) {
  return axios.post('/docusign/updateContractEnvelopeIdAndDocuments', data)
}
// 获取收件人签署状态
export function envelopesRecipients(data) {
  return axios.post('/docusign/envelopesRecipients', data)
}
// 获取合同pdf文件信息
export function contractDocuments(data) {
  return axios.post('/docusign/contractDocuments', data)
}
//查询合同字段
export function getContractField(data) {
  return axios.get('/docusign/getContractField', data)
}
// 获取docusign文本自定义字段
export function getTabDefinitions(data) {
  return axios.get('/docusign/getTabDefinitions', data)
}
// 获取已配置字段映射列表
export function getCustomField(data) {
  return axios.post('/docusign/getCustomField', data)
}

// 新增字段映射
export function createCustomField(data) {
  return axios.post('/docusign/createCustomField', data)
}

// 删除字段映射
export function deleteCustomField(data) {
  return axios.post('/docusign/deleteCustomField', data)
}

// 查询电子合同开通状态
export function getElectronicContractStatus(data) {
  return axios.get('/docusign/getElectronicContractStatus', data)
}

// 修改电子合同开通状态
export function updateElectronicContractStatus(data) {
  return axios.post('/docusign/updateElectronicContractStatus', data)
}
// 解除绑定
export function revokeAuthorization(data) {
  return axios.get('/docusign/revokeAuthorization', data)
}
// 获取授权账号信息 
export function getUserInfo(data) {
  return axios.post('/docusign/getUserInfo', data)
}
//获取对象列表
export function getobjectlistB() {
  return axios.post('object/getAllObjectList')
}

//获取字段
export function getsize(data) {
  return axios.post('emailObject/queryFields', data)
}


// 保存邮件短信模板文件夹
export function saveTemplateFolder(data) {
  return axios.post("/templateFolder/saveTemplateFolder", data);
}
// 查询邮件短信模板文件夹接口
export function queryTemplateFolder(data) {
  return axios.post("/templateFolder/queryTemplateFolder", data);
}
// 删除短信邮件模板文件夹
export function deleteTemplateFolder(data) {
  return axios.post("/templateFolder/deleteTemplateFolder", data);
}
// 保存邮件短信模板
export function saveTemplate(data) {
  return axios.post("/template/saveTemplate", data);
}
// 查询文件夹内模板
export function queryTemplateByFolder(data) {
  return axios.post("/template/queryTemplateByFolder", data);
}
// 删除邮件短信模板
export function deleteTemplate(data) {
  return axios.post("/template/deleteTemplate", data);
}
// 保存邮件短信模板最近查看
export function saveRecentTemplate(data) {
  return axios.post("/template/saveRecentTemplate", data);
}
// 获取邮件短信模板最近查看
export function getRecentTemplate(data) {
  return axios.post("/template/getRecentTemplate", data);
}
// 邮件模板保存图片
export function uploadMailPic(data) {
  return axios.post("/template/uploadMailPic", data);
}

// 专用模板文件夹排序接口
export function sortPrivateTemplate(data) {
  return axios.post("/template/sortPrivateTemplate", data);
}
// 根据名称查询邮件模板
export function queryTemplateByName(data) {
  return axios.post("/template/queryTemplateByName", data);
}
// 根据id获取邮件短信模板
export function queryTemplateById(data) {
  return axios.post("/template/queryTemplateById", data);
}
// 根据id获取文件夹
export function queryTemplateFolderById(data) {
  return axios.post("/templateFolder/queryTemplateFolderById", data);
}

// 绑定法大大电子签账号
export function getAuthUrl(data) {
  return axios.get('/fadada/getAuthUrl', data)
}
// 解绑法大大电子签账号
export function corpDelete(data) {
  return axios.get('/fadada/corpDelete', data)
}
// 查询模板列表
export function getTempalteList(data) {
  return axios.get('/fadada/getTempalteList', data)
}
// 获取合同字段
export function getContractFieldFadada(data) {
  return axios.post('/fadada/getContractField', data)
}
// 新增字段映射关系
export function createCustomFieldFadada(data) {
  return axios.post("/fadada/createCustomField", data);
}
// 更新电子签章状态
export function updateElectronicStatus(data) {
  return axios.post("/fadada/updateElectronicStatus", data);
}
// fadada获取字段映射关系列表
export function getCustomFieldFadada(data) {
  return axios.post("/fadada/getCustomField", data);
}
// fadada删除字段映射关系
export function deleteCustomFieldFadada(data) {
  return axios.post("/fadada/deleteCustomField", data);
}
// 获取企业用户身份信息
export function getIdentityInfo(data) {
  return axios.get('/fadada/getIdentityInfo', data)
}
// 查询当前用户是否需要展示引导页
export function isShowGuidePages() {
  return axios.get('/dingdingbusiness/isShowGuidePages')
}